import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./loadingState";
import * as contentAPI from "../api/content";
import { LanguageCode } from "../hooks/useLanguage";

interface RejectValue {
  rejectValue: string;
}

export const fetchContent = createAsyncThunk<contentAPI.Content, LanguageCode, RejectValue>(
  "content/get",
  async (lang, thunkApi) => {
    try {
      return await contentAPI.getContent(lang);
    } catch (error) {
      console.error(error);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

interface State {
  content?: contentAPI.Content;
  loading: LoadingState;
  error?: string;
}

const initialState: State = {
  content: undefined,
  loading: LoadingState.Idle,
  error: "",
};

const storesSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContent.fulfilled, (state, action: PayloadAction<contentAPI.Content>) => {
      state.content = action.payload;
      state.loading = LoadingState.Succeeded;
    });
    builder.addCase(fetchContent.pending, (state) => {
      state.loading = LoadingState.Pending;
    });
    builder.addCase(fetchContent.rejected, (state, action) => {
      state.loading = LoadingState.Failed;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

const { reducer } = storesSlice;
export default reducer;
