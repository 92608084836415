import styled from "styled-components";
import { brand } from "./colors";

const paddings = {
  small: "2.5rem",
  large: "7.625rem",
};

export const GradientBackground = styled.div`
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentFrame = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 75rem;
  padding: 0rem 11.25rem;
  @media (max-width: 1100px) {
    width: calc(68.75rem - 11.25rem - 11.25rem);
    padding: 0rem 7.5rem;
  }
  @media (max-width: 800px) {
    width: calc(50rem - 7.5rem - 7.5rem);
    padding: 0rem 3.75rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 0rem 0rem;
  }
`;

const ContentBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding-right: ${paddings.large};
  padding-left: ${paddings.large};
  @media (max-width: 767px) {
    padding-right: ${paddings.small};
    padding-left: ${paddings.small};
  }
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  max-width: 75rem;
  border-radius: 0.25rem;

  h2 {
    margin: 0.625rem;
  }
`;

export const TransparentContentBlock = styled(ContentBlock)`
  background-color: transparent;
  color: ${brand.text.white};
`;

export const WhiteContentBlock = styled(ContentBlock)`
  background-color: ${brand.background.primary};
  color: ${brand.text.primary};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  padding-bottom: 1.25rem;
`;

export const H1 = styled.h1`
  font-family: LocatorWeb;
  font-size: 3rem;
  @media (max-width: 1000px) {
    font-size: 2.25rem;
  }
  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: white;
  padding: 0;
  margin: 0;
`;

export const H2 = styled.h2`
  font-family: LocatorWeb;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
`;

export const H3 = styled.h3`
  font-family: LocatorWeb;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: black;
`;

export const H4 = styled.h3`
  font-family: LocatorWeb;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: black;
`;

interface ParagraphProps {
  readonly color?: string;
  readonly fontStyle?: string;
}

export const Paragraph = styled.p`
  font-family: LocatorWeb;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${(props: ParagraphProps) => (props.color ? props.color : "black")};
  font-style: ${(props: ParagraphProps) => (props.fontStyle ? props.fontStyle : "normal")};
`;

export const ParagraphWrapper = styled.div`
  max-width: 35rem;
  align-items: center;
  align-self: center;
  p {
    color: ${(props: ParagraphProps) => (props.color ? props.color : "black")};
  }
`;

export const Space = styled.div`
  height: 1.25rem;
`;

export const Oval = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: solid 0.0625rem #979797;
  border-radius: 0.375rem;
`;

export const LanguageSelectWrapper = styled.div`
  display: flex;
  width: 12.5rem;
  justify-content: flex-end;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SearchWrapper = styled.div`
  padding-left: 6.25rem;
  padding-right: 6.25rem;

  @media (max-width: 1024px) {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  @media (max-width: 768px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const TermsOfUseWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`;

export const TermsOfUseLink = styled.a`
  font-family: LocatorWeb;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

interface ProductImageProps {
  small?: boolean;
}
export const ProductImage = styled.img`
  max-height: ${(props: ProductImageProps) => (props.small ? "11.25rem" : "12.5rem")};
  max-width: ${(props: ProductImageProps) => (props.small ? "11.25rem" : "12.5rem")};
`;
