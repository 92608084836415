import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./loadingState";
import * as wishesAPI from "../api/wishes";

export const createWish = createAsyncThunk<
  wishesAPI.Wish,
  wishesAPI.Wish,
  {
    rejectValue: string;
  }
>("wishes/create", async (data, thunkApi) => {
  try {
    return await wishesAPI.createWish(data);
  } catch (error) {
    console.error(error);
    return thunkApi.rejectWithValue(error.message);
  }
});

interface State {
  wish: wishesAPI.Wish | null;
  loading: LoadingState;
  error?: string;
}

const initialState: State = {
  wish: null,
  loading: LoadingState.Idle,
  error: "",
};

const wishesSlice = createSlice({
  name: "wishes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWish.fulfilled, (state, action: PayloadAction<wishesAPI.Wish>) => {
      state.wish = action.payload;
      state.loading = LoadingState.Succeeded;
    });
    builder.addCase(createWish.pending, (state) => {
      state.loading = LoadingState.Pending;
    });
    builder.addCase(createWish.rejected, (state, action) => {
      state.loading = LoadingState.Failed;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

const { reducer } = wishesSlice;
export default reducer;
