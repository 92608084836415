import axios from "axios";

export interface Product {
  id: string;
  name: string;
  volume: number;
  agentId: number;
  agentName: string;
  price: number;
  mainGroupId: string[];
  mainGroupName: string[];
  productGroupId: string[];
  productGroupName: string[];
  tasteStyleId?: string[];
  tasteStyleName?: string[];
  tasteStyleSparklingName?: string[] | null;
  tasteStyleStrongName?: string[] | null;
  beerStyleName?: string[] | null;
  availability?: boolean;
  countryName: string;
  localProductCanBeWished: boolean;
}

export interface SearchProductsResponse {
  products: Product[];
  count: number;
  potId: string;
}

export const searchProducts = async (
  searchText: string,
  storeId: string,
  lang: string,
): Promise<SearchProductsResponse> => {
  const products = await axios(
    `${process.env.REACT_APP_API_URL}/products/search?searchText=${searchText}&storeId=${storeId}&lang=${lang}`,
  ).then<SearchProductsResponse>((response) => response.data);
  return products;
};
