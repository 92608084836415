import axios from "axios";
import { LanguageCode } from "../hooks/useLanguage";

export type KeyText = string;

export interface TextContent {
  type: string;
  text: string;
  spans: string[];
}

export interface LinkContent {
  link_type: string;
  url: string;
}

export interface ImageContent {
  dimensions: {
    width: number;
    height: number;
  };
  alt: string | null;
  copyright: string | null;
  url: string;
  small?: {
    dimensions: {
      width: number;
      height: number;
    };
    alt: string | null;
    copyright: string | null;
    url: string;
  };
}

export interface TermsOfUsePageContent {
  title: TextContent[];
  date: KeyText;
  document_details_selection: TextContent[];
  terms_of_use_selection: TextContent[];
  return_to_home_page_link: KeyText;
  "alko-logo": ImageContent;
}

export interface MetadataContent {
  title: KeyText;
  description: KeyText;
  og_title: KeyText;
  og_description: KeyText;
  og_image: ImageContent;
  keywords: KeyText;
}

export interface HomePageContent {
  "alko-logo": ImageContent;
  "hero-selection-headline": TextContent[];
  "hero-selection-paragraph": TextContent[];
  send_wish_button: TextContent[];
  send_wish_outside_selection: TextContent[];
  open_wish_form_open_link: KeyText;
}

export interface ThankYouPageContent {
  "alko-logo": ImageContent;
  "hero-selection-headline": TextContent[];
  "hero-selection-paragraph": TextContent[];
  wish_badge: ImageContent;
  open_in_webshop_text?: TextContent[];
  open_in_webshop_link?: LinkContent;
  download_app_text: TextContent[];
  ios_badge: ImageContent;
  download_ios_app_link: LinkContent;
  android_badge: ImageContent;
  download_alko_app_android_link: LinkContent;
  return_to_home_page_link: TextContent[];
  app_store_legal_text: TextContent[];
}

export interface StoreSelectorContent {
  title: TextContent[];
  title_single: TextContent[];
  search_placeholder: TextContent[];
  search_placeholder_short: KeyText;
  selected_store_text: TextContent[];
  change_store: KeyText;
  find_closest_store: KeyText;
  no_results_text: KeyText;
}

export interface ProductSelectorContent {
  title: TextContent[];
  title_single: TextContent[];
  search_placeholder: TextContent[];
  search_placeholder_short: KeyText;
  results_text: TextContent[];
  disclaimer: TextContent[];
  search_button: KeyText;
  next_page: KeyText;
  previous_page: KeyText;
  too_many_results_text: TextContent[];
}

export interface ProductCardContent {
  open_product_details: TextContent[];
  product_selected: TextContent[];
  product_available: TextContent[];
  select_product: TextContent[];
  open_in_webshop_link: LinkContent;
  select_product_aria_text: KeyText;
  open_product_details_aria_text: KeyText;
  product_selected_aria_text: KeyText;
  product_available_aria_text: KeyText;
  price_aria_text: KeyText;
  volume_aria_text: KeyText;
  taste_style_aria_text: KeyText;
}

export interface SuggestNewProductCardContent {
  card_title: TextContent[];
  card_body: TextContent[];
  icon: ImageContent;
  card_button: TextContent[];
  card_link: LinkContent;
}

export interface NotFoundContent {
  "alko-logo": ImageContent;
  title: TextContent[];
  content: TextContent[];
  return_to_home_page_link: KeyText;
}

export interface OpenWishFormContent {
  title: TextContent[];
  send_wish_outside_selection: TextContent[];
  form_open_link: TextContent[];
  open_wish_description: TextContent[];
  product_group_placeholder: KeyText;
  product_group_aria_text: KeyText;
  country_list_placeholder: KeyText;
  country_list_aria_text: KeyText;
  country_list_popular_group: KeyText;
  country_list_other_group: KeyText;
  wish_text_placeholder: KeyText;
  wish_text_aria_text: KeyText;
  send_wish_button: KeyText;
  back_link: KeyText;
}

export interface Content {
  metadata: MetadataContent;
  homepage: HomePageContent;
  termsOfUse: TermsOfUsePageContent;
  thankYou: ThankYouPageContent;
  thankYouOpenWish: ThankYouPageContent;
  storeSelector: StoreSelectorContent;
  productSelector: ProductSelectorContent;
  productCard: ProductCardContent;
  suggestNewProductCard: SuggestNewProductCardContent;
  notFound: NotFoundContent;
  openWishForm: OpenWishFormContent;
}

export const getContent = async (lang: LanguageCode): Promise<Content> => {
  const response = await axios(`${process.env.REACT_APP_API_URL}/content?lang=${lang.toString()}`);
  const data = await response.data;
  const content: Content = data.reduce((c: Content, { type, data }: { type: string; data: unknown }) => {
    switch (type) {
      case "metadata":
        return {
          ...c,
          metadata: data as MetadataContent,
        };
      case "homepage":
        return {
          ...c,
          homepage: data as HomePageContent,
        };
      case "thankyoupage":
        return {
          ...c,
          thankYou: data as ThankYouPageContent,
        };
      case "thank_you_page_open_wish":
        return {
          ...c,
          thankYouOpenWish: data as ThankYouPageContent,
        };
      case "terms_of_use_and_privacy":
        return {
          ...c,
          termsOfUse: data as TermsOfUsePageContent,
        };
      case "store_selector":
        return {
          ...c,
          storeSelector: data as StoreSelectorContent,
        };
      case "product_selector":
        return {
          ...c,
          productSelector: data as ProductSelectorContent,
        };
      case "suggest_new_product_card":
        return {
          ...c,
          suggestNewProductCard: data as SuggestNewProductCardContent,
        };
      case "product_card":
        return {
          ...c,
          productCard: data as ProductCardContent,
        };
      case "open_wish_form":
        return {
          ...c,
          openWishForm: data as OpenWishFormContent,
        };
      case "not_found":
        return {
          ...c,
          notFound: data as NotFoundContent,
        };
      default:
        return c;
    }
  }, {});
  return content;
};
