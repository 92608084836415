import React, { useEffect, useLayoutEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { RootState, useAppDispatch } from "./store";
import { fetchContent } from "./store/content";
import { useLanguage } from "./hooks/useLanguage";
import { useSelector } from "react-redux";
import * as Prismic from "./components/PrismicContent";
import { ThemeProvider, CssBaseline, Box, Typography, Link } from "@material-ui/core";
import { localizedTheme } from "./theme";
import { Redirect as RedirectToUrl } from "./pages/Redirect";
import { ThankYouPage } from "./pages/ThankYouPage";
const Home = lazy(() => import("./pages/Home"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const NotFound = lazy(() => import("./pages/NotFound"));

const oneTrustCookieAutoBlockScript = (otScriptId: string) => (
  <script type="text/javascript" src={`https://cdn.cookielaw.org/consent/${otScriptId}/OtAutoBlock.js`}></script>
);
const oneTrustCookieConsentSDKScript = (otScriptId: string, lang: "fi" | "sv" | "en") => (
  <script
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    type="text/javascript"
    charSet="UTF-8"
    data-language={lang}
    data-domain-script={otScriptId}
  ></script>
);
const oneTrustOptanonWrapperScript = () => <script type="text/javascript">{`function OptanonWrapper() {}`}</script>;

const googleTagManagerInitScript = (gtmId: string) => (
  <script>
    {`
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "${gtmId}");
      `}
  </script>
);

const Head = ({ allowOT }: { allowOT: boolean }) => {
  const dispatch = useAppDispatch();
  const { lang, shortLang } = useLanguage();
  const metadataContent = useSelector((state: RootState) => state.content.content?.metadata);
  const gtmId = process.env.REACT_APP_GTM_ID;
  const oneTrustScriptId = process.env.REACT_APP_ONETRUST_ID;

  useEffect(() => {
    dispatch(fetchContent(lang));
  }, [dispatch, lang]);

  const [allowGtm, setAllowGtm] = React.useState(false);
  useEffect(() => {
    // Observe OneTrust placeholder node to make sure that the OT script has been loaded
    // before enabling Google Tag Manager script.
    // GTM cookie should be blocked by OT until cookie consent has been accepted.
    const observer = new MutationObserver(() => {
      if (window && Object.getOwnPropertyNames(window).includes("Optanon")) {
        setAllowGtm(true);
        observer.disconnect();
      }
    });
    const node = document.getElementById("onetrust-consent-sdk");
    if (node) {
      observer.observe(node, {
        subtree: true,
        childList: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const image = metadataContent?.og_image?.url || "https://alkotoive.fi/alkotoive-1200x630.jpg";
  const title = Prismic.getText(metadataContent?.og_title) || Prismic.getText(metadataContent?.title);
  const description = Prismic.getText(metadataContent?.og_description) || Prismic.getText(metadataContent?.description);

  return (
    <>
      <Helmet htmlAttributes={{ lang: shortLang }}>
        {oneTrustScriptId && allowOT && oneTrustCookieAutoBlockScript(oneTrustScriptId)}
        {oneTrustScriptId && allowOT && oneTrustCookieConsentSDKScript(oneTrustScriptId, shortLang)}
        {oneTrustScriptId && allowOT && oneTrustOptanonWrapperScript()}
        {gtmId && allowGtm ? googleTagManagerInitScript(gtmId) : null}

        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={Prismic.getText(metadataContent?.keywords) || ""} />

        <link rel="canonical" href="https://alkotoive.fi" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://alkotoive.fi" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

const Footer = () => {
  const content = useSelector((state: RootState) => state.content.content?.termsOfUse);

  return (
    <footer>
      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <Box py={1} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
          <Box p={1}>
            <Link href="/terms-of-use" underline="always" color="textSecondary">
              <Typography variant="body1" color="textSecondary" noWrap>
                {Prismic.getText(content?.title)}
              </Typography>
            </Link>
          </Box>
          <Box p={1}>
            <Typography variant="body1" color="textSecondary" className="MuiLink-root">
              <Link
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                color="primary"
                underline="always"
                style={{
                  textTransform: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  borderColor: "none",
                  padding: "0",
                  color: "white",
                  fontSize: "inherit",
                  transition: "none",
                  cursor: "pointer",
                  whiteSpace: "normal",
                  lineHeight: 1.2,
                }}
              >
                {""}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" color="textSecondary">
          © Alko
        </Typography>
      </Box>
    </footer>
  );
};

const App = (): JSX.Element => {
  const { getMuiLocale } = useLanguage();

  const [allowOT, setAllowOT] = React.useState(false);
  useLayoutEffect(() => {
    const node = document.getElementById("ot-sdk-btn");
    if (node) {
      setAllowOT(true);
    }
  }, []);

  return (
    <ThemeProvider theme={localizedTheme(getMuiLocale())}>
      <CssBaseline />
      <Router>
        <Head allowOT={allowOT} />
        <Switch>
          <Route exact path="/">
            <Suspense fallback={<div></div>}>
              <Home />
            </Suspense>
          </Route>
          <Route exact path="/store/:storeId">
            <Suspense fallback={<div></div>}>
              <Home />
            </Suspense>
          </Route>
          <Route exact path="/terms-of-use">
            <Suspense fallback={<div></div>}>
              <TermsOfUse />
            </Suspense>
          </Route>
          <Route exact path="/thank-you">
            <Suspense fallback={<div></div>}>
              <ThankYouPage />
            </Suspense>
          </Route>
          <Route exact path="/admin">
            <RedirectToUrl url="https://wishadmin-dev.alko.fi" />
          </Route>
          <Route path="*">
            <Suspense fallback={<div></div>}>
              <NotFound />
            </Suspense>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
