import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ProductImage } from "../../styles/common";
import { makeStyles } from "@material-ui/core";
// import useImageSize from "../../hooks/useImageSize";

interface Props {
  productId: string;
  productName: string;
  badge: {
    url: string;
    alt?: string | null;
    dimensions: {
      height: number;
      width: number;
    };
  };
}

const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

const useBadgeStyles = makeStyles({
  root: {
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 100,
  },
});
const useProductImageStyles = makeStyles({
  root: {
    margin: "0 auto",
    maxHeight: 250,
    maxWidth: 250,
  },
});
export const WishedProductCard = ({ productId, productName, badge }: Props): JSX.Element | null => {
  const productImageUrl = `https://images.alko.fi/t_digipruuvi,f_auto/cdn/${productId}`;
  // const [height, width] = useImageSize(productImageUrl);
  const classes = useStyles();
  const badgeClasses = useBadgeStyles();
  const productImageClasses = useProductImageStyles();
  if (!badge) return null;
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <Box display="flex" alignItems="flex-start" position="relative">
          <img
            src={badge.url}
            alt={badge.alt || undefined}
            height={badge.dimensions.height}
            width={badge.dimensions.width}
            className={badgeClasses.root}
          />
          <ProductImage src={productImageUrl} className={productImageClasses.root} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box height="100%" display="flex" alignItems="center">
          <Typography variant="h4" align="left">
            {productName}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
