import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ThankYouOverlay from "../components/ThankYouOverlay/ThankYouOverlay";

export const ThankYouPage = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const { product, storeId } = location.state as any;
  const returnToHomepageTimeoutInMs = 10000;

  if (storeId) {
    setTimeout(() => {
      history.push(storeId ? `/store/${storeId}` : "/");
    }, returnToHomepageTimeoutInMs);
  }

  return <ThankYouOverlay wishedProduct={product} />;
};
