import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./loadingState";
import * as productsAPI from "../api/products";

export const fetchProducts = createAsyncThunk<
  productsAPI.SearchProductsResponse,
  { searchText: string; storeId: string; lang: string },
  {
    rejectValue: string;
  }
>("products/search", async ({ searchText, storeId, lang }, thunkApi) => {
  try {
    const response = await productsAPI.searchProducts(searchText, storeId, lang);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error.message);
  }
});

interface State {
  products: productsAPI.Product[];
  count: number;
  potId: string;
  loading: LoadingState;
  error?: string;
}

const initialState: State = {
  products: [],
  count: 0,
  potId: "",
  loading: LoadingState.Idle,
  error: "",
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearProducts: (state) => {
      state.products = [];
      state.loading = LoadingState.Idle;
      state.error = "";
      state.potId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action: PayloadAction<productsAPI.SearchProductsResponse>) => {
      state.products = action.payload.products;
      state.count = action.payload.count;
      state.loading = LoadingState.Succeeded;
      state.potId = action.payload.potId;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = LoadingState.Pending;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = LoadingState.Failed;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

const { actions, reducer } = productsSlice;
export const { clearProducts } = actions;
export default reducer;
