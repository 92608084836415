import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { LanguageProvider } from "./hooks/useLanguage";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </LanguageProvider>
  </Provider>,
  document.getElementById("root"),
);
serviceWorker.unregister();
