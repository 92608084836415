import * as React from "react";
import { fiFI, svSE, enUS, Localization } from "@material-ui/core/locale";

export enum LanguageCode {
  FI = "fi",
  SV = "sv-se",
  EN = "en-gb",
}
export const languageCodeMap = new Map([
  ["fi", LanguageCode.FI],
  ["sv", LanguageCode.SV],
  ["en", LanguageCode.EN],
]);

const localeMap = new Map([
  [LanguageCode.FI, fiFI],
  [LanguageCode.SV, svSE],
  [LanguageCode.EN, enUS],
]);

export const getDefaultLanguage = (): LanguageCode => {
  const savedLanguage = window.localStorage.getItem("appUILang");
  if (savedLanguage) {
    try {
      return savedLanguage as LanguageCode;
    } catch (err) {
      const l = savedLanguage.toLowerCase().substring(0, 2);
      return l === "en" ? LanguageCode.EN : l === "sv" ? LanguageCode.SV : LanguageCode.FI;
    }
  }

  return LanguageCode.FI;
};

const getLocale = (lang: LanguageCode) => localeMap.get(lang) || enUS;

interface LangContextType {
  lang: LanguageCode;
  shortLang: "fi" | "sv" | "en";
  setLang: (l: string) => void;
  getMuiLocale: () => Localization;
}
const LangContext = React.createContext<LangContextType>({
  lang: LanguageCode.FI,
  shortLang: "fi",
  setLang: (l: string) => null,
  getMuiLocale: () => fiFI,
});

interface Props {
  children: React.ReactNode;
}

export const LanguageProvider = ({ children }: Props): JSX.Element => {
  const [lang, setLang] = React.useState<LanguageCode>(getDefaultLanguage());

  const setLanguage = (l: string) => {
    const langCode = languageCodeMap.get(l.slice(0, 2)) || getDefaultLanguage();
    window.localStorage.setItem("appUILang", langCode);
    setLang(langCode);
  };

  const getMuiLocale = () => getLocale(lang);

  const shortLang = lang === LanguageCode.EN ? "en" : lang === LanguageCode.SV ? "sv" : "fi";
  return (
    <LangContext.Provider value={{ lang, shortLang, setLang: setLanguage, getMuiLocale }}>
      {children}
    </LangContext.Provider>
  );
};

export const useLanguage = (): LangContextType => React.useContext(LangContext);
