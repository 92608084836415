import createMuiTheme, { Theme, ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import * as CSS from "csstype";
import { Localization, fiFI } from "@material-ui/core/locale";

const locatorWeb: CSS.AtRule.FontFace = {
  fontFamily: "LocatorWeb",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `local("LocatorWeb"), url("/LocatorWebRegular.woff") format("woff");`,
};
const locatorWebLight: CSS.AtRule.FontFace = {
  fontFamily: "LocatorWeb",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `local("LocatorWeb"), url("/LocatorWebLight.woff") format("woff");`,
};
const locatorWebBold: CSS.AtRule.FontFace = {
  fontFamily: "LocatorWeb",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "bold",
  src: `local("LocatorWeb"), url("/LocatorWebBold.woff") format("woff");`,
};

const rawTheme: ThemeOptions = {
  palette: {
    common: { black: "rgb(0, 0, 0)", white: "rgb(255, 255, 255)" },
    background: { paper: "rgb(255, 255, 255)", default: "rgb(250, 250, 250)" },
    primary: {
      light: "rgb(230, 230, 230)",
      main: "rgb(41, 41, 41)",
      dark: "rgb(0, 0, 0)",
      contrastText: "rgb(255, 255, 255)",
    },
    secondary: {
      light: "rgb(255, 255, 255)",
      main: "rgb(255, 255, 255)",
      dark: "rgb(235, 235, 235)",
      contrastText: "rgb(0, 0, 0)",
    },
    error: {
      light: "rgb(234, 98, 105)",
      main: "rgb(227, 53, 62)",
      dark: "rgb(180, 24, 32)",
      contrastText: "rgb(255, 255, 255)",
    },
    text: {
      primary: "rgb(0, 0, 0)",
      secondary: "rgb(255, 255, 255)",
      disabled: "rgb(107, 107, 107)",
      hint: "rgb(107, 107, 107)",
    },
  },
  typography: {
    fontFamily: "LocatorWeb, Verdana, Arial",
    h1: {
      fontSize: "3rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "inherit",
      padding: "0rem",
      margin: "0rem",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "black",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "black",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "black",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "left",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "left",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [locatorWeb, locatorWebLight, locatorWebBold],
        body: {
          color: "rgb(250,250,250)",
          backgroundColor: "#cd5999",
          backgroundImage: "url(/bg-wide.svg)",
          backgroundSize: "cover",
          "@media (max-width: 800px)": {
            backgroundImage: "url(/bg-narrow.svg)",
          },
          minHeight: "100vh",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        lineHeight: 1.5,
        padding: "0.6875rem 1.3125rem",
      },
      contained: {
        padding: "0.6875rem 1.3125rem",
      },
      containedSizeLarge: {
        padding: "1.0625rem 1.6875rem",
      },
      outlined: {
        padding: "0.625rem 1.25rem",
      },
      text: {
        padding: "0.6875rem 1.3125rem",
      },
      label: {
        textAlign: "center",
        marginBottom: "-0.0625rem",
      },
      iconSizeMedium: {
        marginTop: "-0.0625rem",
      },
      iconSizeLarge: {
        marginTop: "-0.125rem",
      },
    },
    MuiInputBase: {
      // Remove autofill background color added by browser
      input: {
        "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-internal-autofill-selected, &:-internal-autofill-selected:focus, &:-internal-autofill-selected:hover": {
          backgroundColor: "transparent !important",
          border: "0rem",
          "-webkit-text-fill-color": "rgb(41, 41, 41)",
          "-webkit-box-shadow": "0 0 0rem 62.5rem #ffffff inset",
          transition: "background-color 5000s ease-in-out 0s",
          font: "inherit",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgb(41, 41, 41)",
        paddingBottom: "0.4rem",
      },
    },
  },
};

export const localizedTheme = (locale: Localization): Theme => responsiveFontSizes(createMuiTheme(rawTheme, locale));

export default responsiveFontSizes(createMuiTheme(rawTheme, fiFI));
