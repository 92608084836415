import React from "react";
import { useSelector } from "react-redux";
import { Product } from "../../api/products";
import { RootState } from "../../store";
import { ContentWrapper, ThankYouWrapper } from "./ThankYouOverlay.styled";
import { Button, Container, Typography, Box, Link, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { WishedProductCard } from "../WishedProductCard/WishedProductCard";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as Prismic from "../PrismicContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
  }),
);

interface Props {
  wishedProduct?: Product;
}

export const ThankYouOverlay = ({ wishedProduct }: Props): JSX.Element => {
  const classes = useStyles();
  const content = useSelector((state: RootState) => state.content.content?.thankYou);
  const openWishContent = useSelector((state: RootState) => state.content.content?.thankYouOpenWish);
  const termsOfUseContent = useSelector((state: RootState) => state.content.content?.termsOfUse);

  return (
    <ThankYouWrapper>
      <Container maxWidth="md" className={classes.root}>
        <Box pt={{ xs: 0, sm: 4, md: 8 }}>
          <Box
            py={{ xs: 4, sm: 4, md: 8 }}
            px={{ xs: 2, sm: 4, md: 12 }}
            bgcolor="white"
            color="black"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box py={4}>
              {Prismic.getTitle(
                wishedProduct ? content?.["hero-selection-headline"] : openWishContent?.["hero-selection-headline"],
                {
                  style: { color: "primary" },
                },
              )}
            </Box>

            {content &&
              (wishedProduct ? (
                <Box py={4}>
                  <WishedProductCard
                    productId={wishedProduct.id}
                    productName={wishedProduct.name}
                    badge={content.wish_badge}
                  />
                </Box>
              ) : (
                <Box>{Prismic.getImage(content.wish_badge)}</Box>
              ))}

            <Box p={4}>
              <ContentWrapper>
                {Prismic.getContent(
                  wishedProduct ? content?.["hero-selection-paragraph"] : openWishContent?.["hero-selection-paragraph"],
                )}
              </ContentWrapper>
            </Box>

            <Box>
              {wishedProduct && (
                <Box pb={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    focusRipple
                    href={Prismic.getLink(content?.open_in_webshop_link, {
                      replace: { source: "{id}", target: wishedProduct.id },
                    })}
                    target="_blank"
                  >
                    {Prismic.getText(content?.open_in_webshop_text)}
                  </Button>
                </Box>
              )}

              <Box py={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBackIcon fontSize="inherit" />}
                  focusRipple
                  href={"/"}
                  fullWidth
                >
                  {Prismic.getText(content?.return_to_home_page_link)}
                </Button>
              </Box>
            </Box>

            <Box py={1}>
              <Box py={1}>
                <ContentWrapper>{Prismic.getContent(content?.download_app_text)}</ContentWrapper>
              </Box>

              <Grid container direction="row" justify="center">
                <Grid item>
                  <Button href={Prismic.getLink(content?.download_ios_app_link)} disableRipple target="_blank">
                    {Prismic.getImage(content?.ios_badge)}
                  </Button>
                </Grid>
                <Grid item>
                  <Button href={Prismic.getLink(content?.download_alko_app_android_link)} disableRipple target="_blank">
                    {Prismic.getImage(content?.android_badge)}
                  </Button>
                </Grid>
              </Grid>

              <Box pt={2}>
                <ContentWrapper>
                  <Typography variant="body2">{Prismic.getContent(content?.app_store_legal_text)}</Typography>
                </ContentWrapper>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" p={4} bgcolor="rgb(42, 42, 42)">
            <Box width={80} height={80}>
              {Prismic.getImage(content?.["alko-logo"])}
            </Box>
            <Box py={1}>
              <Link href="/terms-of-use" underline="always" color="textSecondary">
                <Typography variant="body1" color="textSecondary" noWrap>
                  {Prismic.getText(termsOfUseContent?.title)}
                </Typography>
              </Link>
            </Box>
            <Typography variant="body1" color="textSecondary">
              © Alko
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThankYouWrapper>
  );
};

export default ThankYouOverlay;
