import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import productsReducer from "./products";
import contentReducer from "./content";
import wishesReducer from "./wishes";

const store = configureStore({
  reducer: {
    products: productsReducer,
    content: contentReducer,
    wishes: wishesReducer,
  },
  middleware: [...getDefaultMiddleware()] as const,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be resused to resolve types

export default store;
