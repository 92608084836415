export const brand = {
  background: {
    primary: "#ffffff",
    secondary: "#e5e5e5",
    dark: {
      primary: "#2a2a2a",
    },
  },
  text: {
    black: "#000000",
    white: "#ffffff",
    primary: "#2a2a2a",
    secondary: "#6d6d6d",
    success: "#5bae33",
    warning: "#f8a542",
    error: "#e3333c",
    contrast: {
      primary: "#ffffff",
      secondary: "#ffffffcc",
    },
  },
  link: "#a3333c",
};

interface TasteStyleColor {
  primary: string;
  secondary: string;
}

const defaultColor: TasteStyleColor = {
  primary: "#2A2A2A",
  secondary: "#FFFFFF",
};

const readyToDrinkColors: { [key: string]: TasteStyleColor } = {
  greippinen: { primary: "#ABE1FB", secondary: "#2A2A2A" },
  sitruksinen: { primary: "#F7EE61", secondary: "#2A2A2A" },
  hedelmäinen: { primary: "#EF7021", secondary: "#FFFFFF" },
  marjaisa: { primary: "#EE3D97", secondary: "#FFFFFF" },
  "maustetut ja muut": { primary: "#A25C98", secondary: "#FFFFFF" },
};

const cognacColors: { [key: string]: TasteStyleColor } = {
  "muut konjakit": { primary: "#7b858a", secondary: "#FFFFFF" },
  "xo-konjakit": { primary: "#89532f", secondary: "#FFFFFF" },
  "extra-konjakit": { primary: "#89532f", secondary: "#FFFFFF" },
  "vsop-konjakit": { primary: "#ff7300", secondary: "#FFFFFF" },
  "vs-konjakit": { primary: "#ecc300", secondary: "#2A2A2A" },
};

const sparklingWineColors: { [key: string]: TasteStyleColor } = {
  "lempeä & makeahko": { primary: "#f07800", secondary: "#FFFFFF" },
  "pehmeä & kepeä": { primary: "#f8e848", secondary: "#2A2A2A" },
  "pirteä & hedelmäinen": { primary: "#5bae33", secondary: "#FFFFFF" },
  "runsas & paahteinen": { primary: "#a05020", secondary: "#FFFFFF" },
  "vivahteikas & ryhdikäs": { primary: "#1281a6", secondary: "#FFFFFF" },
};

const beerColors: { [key: string]: TasteStyleColor } = {
  ale: { primary: "#b03a1e", secondary: "#FFFFFF" },
  erikoisuus: { primary: "#9d305f", secondary: "#FFFFFF" },
  lager: { primary: "#f8a542", secondary: "#2A2A2A" },
  pils: { primary: "#e4d30e", secondary: "#2A2A2A" },
  "stout & porter": { primary: "#241b0e", secondary: "#FFFFFF" },
  "tumma lager": { primary: "#734e29", secondary: "#FFFFFF" },
  "vahva lager": { primary: "#b0811f", secondary: "#2A2A2A" },
  vehnäolut: { primary: "#fade5a", secondary: "#2A2A2A" },
};

const redWineColors: { [key: string]: TasteStyleColor } = {
  "marjaisa & raikas": { primary: "#685ec4", secondary: "#FFFFFF" },
  "mehevä & hilloinen": { primary: "#ce106e", secondary: "#FFFFFF" },
  "pehmeä & hedelmäinen": { primary: "#dcaabf", secondary: "#2A2A2A" },
  "roteva & voimakas": { primary: "#6d3332", secondary: "#FFFFFF" },
  "vivahteikas & kehittynyt": { primary: "#fa511f", secondary: "#FFFFFF" },
};

const whiskyColors: { [key: string]: TasteStyleColor } = {
  "kepeä & viljainen": { primary: "#FBE37F", secondary: "#2A2A2A" },
  "pehmeä & hedelmäinen": { primary: "#31984f", secondary: "#2A2A2A" },
  "runsas & voimakas": { primary: "#774313", secondary: "#FFFFFF" },
  "hedelmäinen & aromikas": { primary: "#E99926", secondary: "#FFFFFF" },
  "vivahteikas & ryhdikäs": { primary: "#4d6eb1", secondary: "#FFFFFF" },
};

export const tasteStyleColors: {
  [key: string]: { [key: string]: TasteStyleColor };
} = {
  juomasekoitukset: readyToDrinkColors,
  konjakit: cognacColors,
  kuohuviinit: sparklingWineColors,
  "kuohuviinit & samppanjat": sparklingWineColors,
  valkoviinit: sparklingWineColors,
  "alkoholittomat kuohuviinit": sparklingWineColors,
  oluet: beerColors,
  punaviinit: redWineColors,
  viskit: whiskyColors,
};

export function colors(style?: string, productGroupName?: string): TasteStyleColor {
  if (!style) {
    return defaultColor;
  }

  if (typeof productGroupName !== "string") {
    return oldColors(style);
  }

  const productGroupColors = tasteStyleColors[productGroupName.toLowerCase()];
  if (productGroupColors) {
    const styleColor = productGroupColors[style.toLowerCase()];
    if (styleColor) {
      return styleColor;
    }
  }
  return defaultColor;
}

export function oldColors(style: string): TasteStyleColor {
  switch (style.toLowerCase()) {
    case "mehevä & hilloinen":
      return { primary: "#ce106e", secondary: "#FFFFFF" };
    case "pirteä & hedelmäinen":
      return { primary: "#5bae33", secondary: "#FFFFFF" };
    case "vivahteikas & kehittynyt":
      return { primary: "#fa511f", secondary: "#FFFFFF" };
    case "vivahteikas & ryhdikäs":
      return { primary: "#1281a6", secondary: "#FFFFFF" };
    case "roteva & voimakas":
      return { primary: "#6d3332", secondary: "#FFFFFF" };
    case "marjaisa & raikas":
      return { primary: "#685ec4", secondary: "#FFFFFF" };
    case "runsas & paahteinen":
      return { primary: "#a05020", secondary: "#FFFFFF" };
    case "pehmeä & hedelmäinen":
      return { primary: "#dcaabf", secondary: "#2A2A2A" };
    case "lempeä & makeahko":
      return { primary: "#f07800", secondary: "#FFFFFF" };
    case "pehmeä & kepeä":
      return { primary: "#f8e848", secondary: "#2A2A2A" };
    case "lager":
    case "helles":
    case "dortmunder":
    case "light":
    case "mannermainen":
    case "märzen":
    case "pale":
    case "wiener":
      return { primary: "#f8a542", secondary: "#2A2A2A" };
    case "tumma lager":
    case "dunkel":
    case "mustaolut":
      return { primary: "#734e29", secondary: "#FFFFFF" };
    case "pils":
    case "imperial pils":
      return { primary: "#e4d30e", secondary: "#2A2A2A" };
    case "vahva lager":
    case "bock":
    case "doppelbock":
    case "maibock":
      return { primary: "#b0811f", secondary: "#2A2A2A" };
    case "vehnäolut":
    case "dunkelweizen":
    case "hefeweizen":
    case "kristallweizen":
    case "vehnäale":
    case "weizenbock":
    case "witbier":
      return { primary: "#fade5a", secondary: "#2A2A2A" };
    case "ale":
    case "bitter":
    case "brown ale":
    case "double ipa":
    case "esb":
    case "golden ale":
    case "ipa":
    case "luostariolut":
    case "mild ale":
    case "old ale":
    case "pale ale":
    case "strong ale":
      return { primary: "#b03a1e", secondary: "#FFFFFF" };
    case "stout & porter":
    case "dry stout":
    case "imperial porter":
    case "imperial stout":
    case "oatmeal stout":
    case "portteri":
    case "stout":
    case "sweet stout":
      return { primary: "#241b0e", secondary: "#FFFFFF" };
    case "muut viskit":
      return { primary: "#7b858a", secondary: "#FFFFFF" };
    case "muut konjakit":
      return { primary: "#7b858a", secondary: "#FFFFFF" };
    case "xo-konjakit":
    case "extra-konjakit":
      return { primary: "#89532f", secondary: "#FFFFFF" };
    case "vsop-konjakit":
      return { primary: "#ff7300", secondary: "#FFFFFF" };
    case "vs-konjakit":
      return { primary: "#ecc300", secondary: "#2A2A2A" };
    case "erikoisuus":
    case "alt":
    case "barley wine":
    case "berliner weisse":
    case "biere de garde":
    case "gueuze":
    case "hedelmäolut":
    case "kölsch":
    case "lambic":
    case "maustettu olut":
    case "mead":
    case "ruisolut":
    case "sahti":
    case "saison":
    case "savuolut":
    case "sour ale":
    case "trappisti":
      return { primary: "#9d305f", secondary: "#FFFFFF" };
    case "greippinen":
      return { primary: "#ABE1FB", secondary: "#2A2A2A" };
    case "sitruksinen":
      return { primary: "#F7EE61", secondary: "#2A2A2A" };
    case "hedelmäinen":
      return { primary: "#EF7021", secondary: "#FFFFFF" };
    case "marjaisa":
      return { primary: "#EE3D97", secondary: "#FFFFFF" };
    case "maustetut ja muut":
      return { primary: "#A25C98", secondary: "#FFFFFF" };
    case "kepeä & viljainen":
      return { primary: "#FBE37F", secondary: "#2A2A2A" };
    case "runsas & voimakas":
      return { primary: "#774313", secondary: "#FFFFFF" };
    case "hedelmäinen & aromikas":
      return { primary: "#E99925", secondary: "#FFFFFF" };

    default:
      return defaultColor;
  }
}
