export const findLocation = async (): Promise<
  | {
      city: string;
      region: string;
      region_code: string;
      country: string;
      country_name: string;
      postal: string;
      latitude: number;
      longitude: number;
    }
  | undefined
> => {
  const ipAddress = await fetch("https://api.ipify.org").then((result) => result.text());
  const data = await fetch(`https://ipapi.co/${ipAddress}/json/`).then((response) => response.json());
  if (data.error) {
    return undefined;
  }
  return data;
};
