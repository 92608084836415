import React from "react";
import { RichText, RichTextBlock } from "prismic-reactjs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styled from "@material-ui/core/styles/styled";
import { isArray } from "lodash";
import { ImageContent, KeyText, LinkContent, TextContent } from "../api/content";

interface TextReplacement {
  source: string;
  target: string | number;
}
interface TextProcessingOptions {
  replace?: TextReplacement | TextReplacement[];
  style?: any;
  paragraph?: {
    small?: boolean;
  };
}

const ContentBox = styled(Box)({
  "& h1": {
    fontSize: 48,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: "inherit",
    padding: 0,
    margin: 0,
  },
  "& h2": {
    fontSize: 32,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: "black",
  },
  "& h3": {
    fontSize: 24,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: "black",
  },
  "& h4": {
    fontSize: 18,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: "black",
  },
  "& p": {
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    lineHeight: 1.5,
  },
});

const types = {
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  paragraph: "body1",
  paragraph2: "body2",
} as any;

export const getTitle = (data?: TextContent[], options?: TextProcessingOptions): JSX.Element | null => {
  if (data && data.length > 0) {
    const title = data[0];
    return createElement(title, "title", options);
  }
  return null;
};

export const getLink = (data?: LinkContent, options?: TextProcessingOptions): string => {
  if (data) {
    return processText(data.url, options)[0].trim();
  }
  return "";
};

export const getText = (data?: KeyText | TextContent[], options?: TextProcessingOptions): string => {
  if (data) {
    if (typeof data === "object" && data.length > 0) {
      const prismicElement = data[0];
      return processText(prismicElement.text, options)[0].trim();
    }
    if (typeof data === "string") {
      return processText(data, options)[0].trim();
    }
  }
  return "";
};

export const getImage = (
  data?: ImageContent,
  variant: "small" | null = null,
  options?: TextProcessingOptions,
): JSX.Element | null => {
  if (variant && data) {
    const variantData = data[variant];
    if (variantData) {
      return (
        <img
          src={variantData.url}
          alt={variantData.alt || ""}
          width={variantData.dimensions.width}
          height={variantData.dimensions.height}
          style={{ ...(options?.style ? options?.style : undefined) }}
        />
      );
    }
  }
  if (data) {
    return (
      <img
        src={data.url}
        alt={data.alt || ""}
        width={data.dimensions.width}
        height={data.dimensions.height}
        style={{ ...(options?.style ? options?.style : undefined) }}
      />
    );
  }
  return null;
};

export const getContent = (data?: TextContent[], options?: TextProcessingOptions): (JSX.Element | null)[] | null => {
  if (data && data.length > 0) {
    return data.map((element: any, index: number) => createElement(element, index, options));
  }
  return null;
};

export const getRichText = (data?: TextContent[]): JSX.Element | null => {
  if (data && data.length > 0) {
    return (
      <ContentBox>
        <RichText render={(data as unknown[]) as RichTextBlock[]} />
      </ContentBox>
    );
  }
  return null;
};

const getVariant = (prismicType: string, options?: TextProcessingOptions) => {
  let variant = types[prismicType];
  if (variant) {
    if (variant === "body1" && options?.paragraph?.small) {
      variant = "body2";
    }
  }
  return variant;
};

const createElement = (prismicElement: any, key: any, options?: TextProcessingOptions) => {
  const variant = getVariant(prismicElement.type, options);

  if (variant) {
    const content = processText(prismicElement.text, options);
    return (
      <Typography key={key} variant={variant} {...(options?.style ? options?.style : undefined)}>
        <span key={`${key}-0`}>{content[0]}</span>
        {content.slice(1).map((c: string, i: number) => (
          <span key={`${key}-${i}`}>
            <br />
            {c}
          </span>
        ))}
      </Typography>
    );
  }
  if (process.env.NODE_ENV === "development") {
    console.error("Content type missing: " + prismicElement.type);
  }
  return null;
};

const processText = (text: string, options?: TextProcessingOptions) => {
  if (!text) return [];

  let output = text;
  if (options) {
    Object.keys(options).forEach((action: string) => {
      switch (action) {
        case "replace": {
          const replace = options.replace;
          if (replace) {
            if (isArray(options.replace)) {
              options.replace.forEach((r) => {
                const { source, target } = r;
                output = output.replace(source, target.toString());
              });
            } else {
              const { source, target } = replace as TextReplacement;
              output = output.replace(source, target.toString());
            }
          }
        }
      }
    });
  }
  return output.split("\n");
};
