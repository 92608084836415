import styled from "styled-components";
import { brand } from "../../styles/colors";
import { WhiteContentBlock } from "../../styles/common";

export const ThankYouWrapper = styled.div`
  margin: 0;
  min-height: 100vh;
  background: ${brand.background.dark.primary};
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: max-content;
`;

export const ContentWrapper = styled.div`
  & p {
    text-align: center;
  }
`;

export const ThankYouContentBlock = styled(WhiteContentBlock)`
  padding: 3.75rem;
  max-width: 55rem;
  @media (max-width: 767px) {
    padding: 2.25rem 3.125rem 2.25rem 2.5rem;
    max-width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WhiteButtonLink = styled.a`
  width: 16.25rem;
  height: 2.75rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: LocatorWeb;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
  margin-top: 1.25rem;
  text-decoration: none;
`;

export const SendButtonWrapper = styled.div`
  display: flex;
  padding-top: 0.9375rem;
  justify-content: center;
`;
