import axios from "axios";
import { findLocation } from "../utils/ipLocation";

export enum WishStatus {
  NEW = "NEW",
  ACTIVATED = "ACTIVATED",
  PLANNING = "PLANNING",
  IN_SHELF = "IN_SHELF",
  OLD_PRODUCT = "OLD_PRODUCT",
  REJECTED = "REJECTED",
  WARNING = "WARNING",
  BLOCKED = "BLOCKED",
}

interface Location {
  city: string | null;
  region: string | null;
  regionCode: string | null;
  country: string | null;
  countryName: string | null;
  postal: string | null;
}
export interface Wish {
  store:
    | {
        id: string;
        name: string;
      }
    | { id: null; name: null };
  product?: {
    id: string;
    name: string;
    mainGroupId: string[];
    mainGroupName: string[];
    productGroupId: string[];
    productGroupName: string[];
    price: number;
    agentId: number;
    agentName: string;
  };
  status: WishStatus;
  comment?: string;
  openWish?: {
    productGroup: {
      id: string;
      name: {
        [key: string]: string;
      };
      productGroupIds: string[];
    };
    country?: string;
    wishText: string;
  };
  location?: Location;
  potId?: string;
}

export const createWish = async (wish: Wish): Promise<Wish> => {
  let location: Location = {
    city: null,
    region: null,
    regionCode: null,
    country: null,
    countryName: null,
    postal: null,
  };
  try {
    const ipData = await findLocation();
    if (ipData) {
      location = {
        city: ipData.city,
        region: ipData.region,
        regionCode: ipData.region_code,
        country: ipData.country,
        countryName: ipData.country_name,
        postal: ipData.postal,
      };
    }
  } catch (err) {
    console.log("Cannot fetch location");
  }

  const options = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/wishes`,
    data: {
      ...wish,
      location,
    },
    headers: {
      "Content-Type": "application/json",
    },
  } as any;
  const response = await axios(options).then((response) => response.data);
  return response;
};
